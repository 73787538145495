import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './Redux/Stores/Store';
import './index.css';

import AdminView from './Admin/AdminView.jsx';
import Auctions from './Auction/Auctions.jsx';
import Jewelries from './Jewelry/Jewelries.jsx';
import App from './Login/App.jsx';
import ModerationView from './Moderation/ModerationView.jsx';
import JewelryItemCard from './Production/JewelryItemCard.jsx';
import Production from './Production/Production.jsx';
import Sources from './Source/Sources.jsx';
import Users from './User/Users.jsx';
import Subscription from './User/Subscription.jsx';
import SourcesToParse from './ParsingSources/SourcesToParse.jsx';
import AuctionsToParse from './ParsingSources/AuctionsToParse.jsx';
import { WebSocketProvider } from './Hooks/WebSocketProvider.jsx';
import { SuccessNotificationProvider } from './Notifications/SuccessNotificationProvider.jsx';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <WebSocketProvider>
            <SuccessNotificationProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                        <Route path="/sources" element={<Sources />} />
                        <Route path="/login" element={<App />} />
                        <Route path="/auctions" element={<Auctions />} />
                        <Route path="/jewelries" element={<Jewelries />} />
                        <Route path="/jewelry/:id" element={<JewelryItemCard />} />
                        <Route path="/moderation" element={<ModerationView />} />
                        <Route path="/production" element={<Production />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/:userId/subscription" element={<Subscription />} />
                        <Route path="/admin" element={<AdminView />} />
                        <Route path="/parsing" element={<SourcesToParse />} />
                        <Route path="/auctions-to-parse" element={<AuctionsToParse />} />
                    </Routes>
                </BrowserRouter>
            </SuccessNotificationProvider>
        </WebSocketProvider>
    </Provider>
);