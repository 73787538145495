import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/Header.jsx';
import SourceTable from './SourceTable.jsx';

export default function Sources() {
    const [mineSources, setMineSources] = useState([]);
    const [allSources, setAllSources] = useState([]);
    const [missingPricesSources, setMissingPricesSources] = useState([]);
    const [missingEstimatesSources, setMissingEstimatesSources] = useState([]);
    const [missingPhotosSources, setMissingPhotosSources] = useState([]);

    const [filter, setFilter] = useState('');
    const [sourceFilter, setSourceFilter] = useState('mine');
    const [statusFilter, setStatusFilter] = useState('To Check');
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);

    const fetchSources = async (url, setSourceState) => {
        setIsLoaded(false);
        const accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            setSourceState(response.data);
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                navigate('/login');
            }
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        let url;
        let setSourceState;

        if (sourceFilter === 'mine') {
            url = `${process.env.REACT_APP_BASE_URL}/admin/sources/by`;
            setSourceState = setMineSources;
        } else if (sourceFilter === 'all') {
            url = `${process.env.REACT_APP_BASE_URL}/admin/sources`;
            setSourceState = setAllSources;
        } else if (sourceFilter === 'missingPrices') {
            url = `${process.env.REACT_APP_BASE_URL}/admin/sources/missingPrices`;
            setSourceState = setMissingPricesSources;
            setFilter('past');
        } else if (sourceFilter === 'missingEstimates') {
            url = `${process.env.REACT_APP_BASE_URL}/admin/sources/missingEstimates`;
            setSourceState = setMissingEstimatesSources;
            setFilter('upcoming');
        } else if (sourceFilter === 'missingPhotos') {
            url = `${process.env.REACT_APP_BASE_URL}/admin/sources/missingPhotos`;
            setSourceState = setMissingPhotosSources;
        }

        fetchSources(url, setSourceState);

        if (['mine', 'all', 'missingPrices', 'missingEstimates', 'missingPhotos'].includes(sourceFilter)) {
            setStatusFilter('');
        } else {
            setStatusFilter('To Check');
        }
    }, [sourceFilter, navigate]);

    const handleSourceFilterChange = (newFilter) => {
        setSourceFilter(newFilter);
    };

    return (
        <>
            <Header />
            <SourceTable
                initialSources={
                    sourceFilter === 'mine'
                        ? mineSources
                        : sourceFilter === 'all'
                            ? allSources
                            : sourceFilter === 'missingPrices'
                                ? missingPricesSources
                                : sourceFilter === 'missingEstimates'
                                    ? missingEstimatesSources
                                    : missingPhotosSources
                }
                sourceFilter={sourceFilter}
                handleSourceFilterChange={handleSourceFilterChange}
                setStatusFilter={setStatusFilter}
                statusFilter={statusFilter}
                isLoaded={isLoaded}
                resultsFilter={filter}
            />
        </>
    );
}
