import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function SuccessNotificationProvider({ children }) {
    return (
        <>
            {children}
            <ToastContainer position="top-center" autoClose={3000} />
        </>
    );
}