import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const accessToken = localStorage.getItem("tokenKey");
        const userEmail = localStorage.getItem("email");

        if (!accessToken || !userEmail) {
            console.warn("No access token or user email found");
            return;
        }

        const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}?email=${userEmail}`);

        ws.onopen = () => {
            console.log("WebSocket connected");
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);

            if (message) {
                toast.success(`${message}`, {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        };

        ws.onclose = () => {
            console.log("WebSocket disconnected");
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, []);

    return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => useContext(WebSocketContext);