import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/SourceTable.css';
import TableMenu from './TableMenu.jsx';
import Pagination from './Pagination.jsx';
import SourceStatusIndicator from './SourceStatusIndicator.jsx';
import ChangeJiraTicketStatus from './ChangeJiraTicketStatus.jsx';

export default function SourceTable({
    initialSources,
    sourceFilter,
    handleSourceFilterChange,
    statusFilter,
    setStatusFilter,
    isLoaded,
    resultsFilter
}) {
    const navigate = useNavigate();
    const [sources, setSources] = useState(initialSources);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState(resultsFilter ?? 'upcoming');

    const [serverFilter, setServerFilter] = useState('test');
    const [editable, setEditable] = useState({ name: '', url: '' });
    const [editRowId, setEditRowId] = useState(null);
    const accessToken = localStorage.getItem('tokenKey');
    localStorage.setItem('serverType', 'test');

    useEffect(() => {
        setSources(initialSources);
    }, [initialSources]);

    useEffect(() => {
        setFilter(resultsFilter ?? 'upcoming');
    }, [resultsFilter]);

    const displayedSources = useMemo(() => {
        return sources;

        let filtered = sources;
        if (statusFilter) {
            filtered = filtered.filter(source => source.status === statusFilter);
        }

        if (filter === 'past') {
            filtered = filtered.filter(source => !source.name.includes('Upcoming'));
        } else if (filter === 'upcoming') {
            filtered = filtered.filter(source => source.name.includes('Upcoming'));
        }

        if (searchValue) {
            filtered = filtered.filter(source =>
                source.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        return filtered.sort((a, b) => {
            if (orderBy === 'name') {
                return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else {
                return order === 'asc' ? a.id - b.id : b.id - a.id;
            }
        });
    }, [sources, filter, searchValue, orderBy, order, statusFilter]);

    const handleChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0);
    };

    const handleOpenSource = (sourceId) => {
        localStorage.setItem("sourceId", sourceId);
        navigate('/auctions');
    };

    const requestSort = (key) => {
        const isAsc = orderBy === key && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(key);
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };

    const handleStatusFilterChange = (newFilter) => {
        setStatusFilter(newFilter);
    };

    const handleServerFilterChange = (newFilter) => {
        setServerFilter(newFilter);

        localStorage.removeItem('serverType');
        localStorage.setItem('serverType', newFilter);
    };

    const handleStatusChange = async (sourceId, newStatus, issueId) => {
        const email = localStorage.getItem('email');
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sourceId: sourceId, status: newStatus, issueId: issueId, email: email })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/mine`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setSources(sources =>
                sources.map(src => {
                    if (src.id === sourceId) {
                        return { ...src, status: newStatus, issueId: issueId };
                    }
                    return src;
                })
            );
        } catch (error) {
            console.error('Error updating source:', error);
        }
    };

    const handleEditClick = (source) => {
        setEditRowId(source.id);
        setEditable({ name: source.name, url: source.url });
    };

    const handleSave = async (id) => {
        const currentSource = sources.find(source => source.id === id);

        if (currentSource.name !== editable.name || currentSource.url !== editable.url) {
            const updatedSourceData = {
                id: id,
                name: editable.name,
                url: editable.url
            };

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedSourceData)
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources`, requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const newSources = sources.map((source) => {
                    if (source.id === id) {
                        return { ...source, name: editable.name, url: editable.url };
                    }
                    return source;
                });
                setSources(newSources);
            } catch (error) {
                console.error('Error updating source:', error);
            }
        }

        setEditRowId(null);
    };

    const handleReload = async (sourceId) => {
        if (!accessToken) {
            console.error("Access token is missing.");
            return;
        }

        console.log(JSON.stringify({ sourceId }));
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sourceId })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/reload`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
        } catch (error) {
            console.error('Error reloading source:', error);
        }
    };

    return (
        <div className="SourceTable">
            <TableMenu
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filter={filter}
                statusFilter={statusFilter}
                serverFilter={serverFilter}
                handleServerChange={handleServerFilterChange}
                handleStatusFilterChange={handleStatusFilterChange}
                handleFilterChange={handleFilterChange}
                sourceFilter={sourceFilter}
                handleSourceFilterChange={handleSourceFilterChange}
            />

            <table>
                <thead>
                    <tr>
                        <th className="id" style={{ textAlign: 'center', backgroundColor: '#fafafa' }} onClick={() => requestSort('id')}>Id</th>
                        <th className="title" style={{ backgroundColor: '#fafafa' }} onClick={() => requestSort('name')}>Title</th>
                        <th className="dark-header" style={{ width: '200px', textAlign: 'center' }}>Status</th>
                        <th className="url" style={{ textAlign: 'center', backgroundColor: '#fafafa' }}>URL</th>
                        <th className="url" style={{ textAlign: 'center', backgroundColor: '#fafafa', width: '160px' }}>Actions</th>
                    </tr>
                </thead>
                {isLoaded ? (
                    <tbody>
                        {displayedSources.slice(
                            currentPage * rowsPerPage,
                            (currentPage + 1) * rowsPerPage
                        ).map((source) => (
                            <tr key={source.id}>
                                <td className="id" style={{ textAlign: 'center' }}>{source.id}</td>
                                <td className="title">
                                    {
                                        editRowId === source.id ?
                                            <input
                                                type="text"
                                                className="editInput"
                                                value={editable.name}
                                                onChange={(e) => setEditable({ ...editable, name: e.target.value })}
                                            />
                                            : <span onClick={() => handleOpenSource(source.id)}>{source.name}</span>
                                    }
                                </td>
                                {sourceFilter === 'jira' && (
                                    <td className="table-cell">
                                        <ChangeJiraTicketStatus
                                            sourceId={source.id}
                                            issueId={source.issueId}
                                            filter={source.status}
                                            handleFilterChange={(newStatus) => handleStatusChange(source.id, newStatus, source.issueId)}
                                        />
                                    </td>
                                )}
                                <td
                                    className="table-cell"
                                    style=
                                    {{
                                        textAlign: 'center',
                                        padding: "7px",
                                        width: "250px",
                                        height: "0px"
                                    }}>
                                    <SourceStatusIndicator
                                        countPublishedAuctions={source.countPublishedAuctions}
                                        countPendingAuctions={source.countPendingAuctions}
                                        countDeletedAuctions={source.countDeletedAuctions}
                                    />
                                </td>
                                <td className="url">
                                    {
                                        editRowId === source.id ?
                                            <input
                                                type="url"
                                                value={editable.url}
                                                className="editInput"
                                                onChange={(e) => setEditable({ ...editable, url: e.target.value })}
                                            />
                                            : <a href={source.url} target="_blank" rel="noopener noreferrer">{source.url}</a>
                                    }
                                </td>
                                <td className="actions" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                    {
                                        editRowId === source.id ?
                                            <button className="ActionButton SaveSourceButton" onClick={() => handleSave(source.id)}>
                                                Save
                                            </button>
                                            :
                                            <button className="ActionButton EditSourceButton" onClick={() => handleEditClick(source)}>
                                                Edit
                                            </button>
                                    }
                                    <button
                                        style={{ backgroundColor: 'red' }}
                                        className="ActionButton"
                                        onClick={() => handleReload(source.id)}>
                                        Reload
                                    </button>
                                    {source.countUnChecked !== undefined && (
                                        <span
                                            style={{
                                                backgroundColor: source.countUnChecked === 0 ? '#7FFF00' : '#FF004F',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                borderRadius: '50%',
                                                padding: '5px',
                                                textAlign: 'center',
                                                display: 'inline-block',
                                                minWidth: '20px',
                                                lineHeight: '20px'
                                            }}
                                        >
                                            {source.countUnChecked}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <div>Loading...</div>
                )}
            </table>

            <Pagination
                rowsPerPage={rowsPerPage}
                setPage={setCurrentPage}
                filteredAuctionsLength={displayedSources.length}
                page={currentPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage} />
        </div>
    );
}